import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const resetServices = () => {
  $(`#campaign-service-value`).html(null);
  $(`.campaign-service-input`).val(null);

  $("#service-results .service-item").remove();
};

const resetCategories = () => {
  $(`#campaign-category-value`).html(null);
  $(`.campaign-category-input`).val(null);

  $("#category-results .category-item").remove();
};

// Connects to data-controller="campaign-url"
export default class extends Controller {
  categories(e) {
    Turbo.visit(
      `/admin/campaign_urls/categories?selected_branch_id=${e.currentTarget.dataset.id}`,
      { frame: "categories" }
    );

    resetServices();
    resetCategories();
  }

  services(e) {
    Turbo.visit(
      `/admin/campaign_urls/services?selected_branch_id=${e.currentTarget.dataset.branchId}&selected_category_id=${e.currentTarget.dataset.id}`,
      { frame: "services" }
    );
    resetServices();
  }
}
