import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="campaign-url-value"
export default class extends Controller {
  connect() {
    const object = this.element.dataset.object;
    const id = this.element.dataset.id;
    const branchId = this.element.dataset.branchId;

    switch (object) {
      case "branch":
        Turbo.visit(
          `/admin/campaign_urls/categories?selected_branch_id=${id}`,
          { frame: "categories" }
        );

        return;

      case "category":
        Turbo.visit(
          `/admin/campaign_urls/services?selected_branch_id=${branchId}&selected_category_id=${id}`,
          { frame: "services" }
        );

        return;
    }
  }
}
