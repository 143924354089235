import { ipv4 } from "ipify.js/lib";

export const comentlyClient = (event, params= {}) => {
  if (process.env.SENT_METRIC_TO_COMETLY.toString() === 'false') return
  
  const fetchEvent = async (value) => {
    const fingerprint = value;
    const token = cometToken();
    const ip = await ipv4()
    
    fetch('/create_event', {
      method: 'POST',
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        event_name: event,
        comet_token: token,
        fingerprint: fingerprint,
        user_agent: window.navigator.userAgent,
        ip: ip,
        ...params
      })
    })
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error('Fetch error:', error);
    });
  }
  
  cometFingerprint().then(value => fetchEvent(value))
}
